import axios from "axios";
import * as cookies from "vue-cookies";
import * as moment from "moment/moment";

export async function createQuote({ commit }, data) {
  try {
    commit("setLoading", true, { root: true });

    const headers = {
      authorization: `Bearer ${cookies.get("token")}`,
    };

    await axios
      .post(`/quotes`, data, { headers })
      .then((response) => {
        commit("setSuccess", "Cotización creada con éxito");
      })
      .catch((err) => {
        commit("setError", err.response.data.message);
        console.log(err.response.data.message);
      });
  } catch (error) {
    console.log(error);
    commit("setError", err.response);
  } finally {
    commit("setLoading", false, { root: true });
  }
}

export async function getQuotes({ commit }, data) {
  try {
    commit("setLoading", true, { root: true });

    const headers = {
      authorization: `Bearer ${cookies.get("token")}`,
    };

    if (data == undefined) {
      data = "/";
    }

    await axios
      .get(`/quotes${data}`, { headers })
      .then((response) => {
        commit("setQuotes", response.data);
      })
      .catch((err) => {
        commit("setError", err.response.data.message);
      });
  } catch (error) {
    commit("setError", error.response.data.message);
  } finally {
    commit("setLoading", false, { root: true });
  }
}

export async function sendQuote({ commit }, data) {
  try {
    commit("setLoading", true, { root: true });

    const headers = {
      authorization: `Bearer ${cookies.get("token")}`,
    };

    await axios
      .post(`/quotes/send/`, data, { headers })
      .then((response) => {
        commit("setSuccess", "Cotización enviada");
      })
      .catch((err) => {
        commit("setError", err.response.data.message);
      });
  } catch (error) {
    commit("setError", error.response.data.message);
  } finally {
    commit("setLoading", false, { root: true });
  }
}

export async function getQuote({ commit }, id) {
  try {
    commit("setLoading", true, { root: true });

    const headers = {
      authorization: `Bearer ${cookies.get("token")}`,
    };

    await axios
      .get(`/quotes/${id}`, { headers })
      .then((response) => {
        response.data.createdAt = moment(response.data.createdAt).format("L");
        commit("setQuote", response.data);
      })
      .catch((err) => {
        commit("setError", err.response.data.message);
      });
  } catch (error) {
    commit("setError", error.response.data.message);
  } finally {
    commit("setLoading", false, { root: true });
  }
}

export async function deleteQuote({ commit }, id) {
  try {
    commit("setLoading", true, { root: true });

    const headers = {
      authorization: `Bearer ${cookies.get("token")}`,
    };

    await axios
      .delete(`/quotes/${id}`, { headers })
      .then((response) => {
        commit("setSuccess", response.data.message);
      })
      .catch((err) => {
        commit("setError", err.response.data.message);
      });
  } catch (error) {
    commit("setError", error.response.data.message);
  } finally {
    commit("setLoading", false, { root: true });
  }
}

export async function updateQuote({ commit }, data) {
  try {
    commit("setLoading", true, { root: true });

    const headers = {
      authorization: `Bearer ${cookies.get("token")}`,
    };

    await axios
      .put(`/quotes/${data._id}`, data, { headers })
      .then((response) => {
        commit("setQuote", response.data);
      })
      .catch((err) => {
        commit("setError", err.response.data.message);
      });
  } catch (error) {
    commit("setError", error.response.data.message);
  } finally {
    commit("setLoading", false, { root: true });
  }
}
